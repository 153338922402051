<template>
  <div class="flex w-full">
    <div class="flex mt-6 flex-grow">
      <Menu @click.stop="" class="w-10 h-10" has-border v-if="showSortFilter">
        <template v-slot:title>
          <Icon
            class-name="text-darkPurple h-full mx-auto self-center"
            size="s"
            icon-name="arrow_exchange"
          />
        </template>
        <template v-slot:dropdownTitle> SORT BY: </template>
        <div
          v-for="(option, index) in sortOptions"
          class="text-sm mx-2 px-2 pt-3 cursor-pointer"
          style="width: 180px"
          @click="handleSort(option.name)"
          :key="index"
        >
          {{ option.name }}
        </div>
      </Menu>
      <div class="flex ml-3 -mt-2" v-if="showSearch">
        <SearchInput
          :placeholder="searchPlaceholder"
          v-model="handleSearch"
          class="search"
          style="width: 300px; margin-top: 0.1rem"
        />
        <div v-if="showfilter" @click="$emit('actionModal', true)">
          <Icon
            class-name="text-darkPurple h-full mx-auto -ml-6 mt-1 self-center mr-2
        cursor-pointer"
            size="xs"
            icon-name="sort_size"
          />
        </div>
      </div>
    </div>

    <div class="flex items-end">
      <!-- For adding custom items to  the left side of the card footer -->
      <slot name="leftItems"> </slot>

      <div class="w-auto h-10 flex" v-if="showSelection">
        <div>
          <Menu class="w-11 ml-3 selection">
            <template v-slot:title>
              <div class="flex w-full">
                <p class="flex-grow">{{ showOption }}</p>
                <div class="text-darkPurple">
                  <Icon icon-name="show_more" size="xs" class="mt-1" />
                </div>
              </div>
            </template>
            <ul class="cursor-pointer pl-5 pr-3 py-2 list-none">
              <li
                v-for="(option, index) in options"
                :class="{ 'text-flame': showOption === option }"
                class="pb-2"
                @click="handleSelect(option)"
                :key="index"
              >
                {{ option }}
              </li>
            </ul>
          </Menu>
        </div>
      </div>
      <Card class="w-auto h-10 ml-3 flex" v-if="showBulkActions">
        <div class="flex cursor-pointer" style="width: 160px; padding: 5px 8px">
          <Menu @click.stop="" class="w-auto h-10" right top="50" margin="-25">
            <template v-slot:title>
              <div class="w-full flex">
                <div
                  class="text-flame h-full"
                  style="width: 100px; font-size: 16px; margin: -7px 6px"
                >
                  Bulk Actions
                </div>
                <icon icon-name="caret-down" class="icon -mt-2 text-flame" />
              </div>
            </template>
            <template>
              <div class="flex flex-col w-48 justify-start items-start">
                <div
                  class="flex justify-between items-center cursor-pointer px-3 py-2 mt-1"
                  v-for="(boptions, index) in bulkOptions"
                  :key="index"
                  @click="$emit('action', boptions.action)"
                >
                  <Icon :icon-name="boptions.icon" class-name=" mr-2 w-5 h-5" />
                  <span class="text-darkPurple text-sm leading-5 font-normal">{{
                    boptions.text
                  }}</span>
                </div>
              </div>
            </template>
          </Menu>
        </div>
      </Card>
      <Card class="w-auto h-10 ml-3 flex" v-if="showcalendar">
        <div
          class="flex cursor-pointer"
          style="width: 180px; padding: 5px 8px"
          @click="$emit('calendar')"
        >
          <icon icon-name="calendar" class="icon mt-1" />
          <div
            class="border-r"
            style="width: 1px; margin: 8px 8px; border-color: #321c3b"
          />
          <div
            class="text-darkPurple h-full"
            style="width: 100px; font-size: 16px; margin: 4px 6px"
          >
            Year to Date
          </div>
          <icon icon-name="caret-down" class="icon mt-1" />
        </div>
      </Card>
      <Card v-feature-request class="w-10 h-10 flex ml-3" v-if="printcard">
        <Icon
          class-name="text-darkPurple cursor-pointer mx-auto self-center cursor-pointer"
          size="s"
          @click.native="$emit('print')"
          icon-name="print"
        />
      </Card>
      <Card class="w-10 h-10 flex ml-3" v-if="reloadcard">
        <Icon
          class-name="text-darkPurple cursor-pointer mx-auto self-center"
          size="xs"
          @click.native="$emit('reload')"
          icon-name="undo"
        />
      </Card>
      <Card v-if="showMapPin" class="w-10 h-10 flex ml-3">
        <Icon
          class-name="text-darkPurple mx-auto self-center"
          size="xs"
          icon-name="map-pin"
        />
      </Card>
      <!-- Use the card bellow only for Ess Request -->
      <Card
        class="w-auto h-10 flex ml-3"
        v-if="buttoncard && !hasSubscriptionExpired()"
      >
        <Button :disabled="buttonDisabled"
          class=" text-white" @click="handleClick"
          :background="appearance.buttonColor || defaultBTNColor"
        >
          {{ buttontext }}
        </Button>
      </Card>
      <!-- For adding custom items to  the right side of the card footer -->
      <slot name="rightItems"> </slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Icon from "./Icon";
import Menu from "./Menu";
import Card from "./Card";
import Button from "./Button";

export default {
  components: {
    Icon,
    SearchInput,
    Menu,
    Card,
    Button,
  },
  props: {
    buttoncard: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: "",
    },
    printcard: {
      type: Boolean,
      default: false,
    },
    dropdownSelect: {
      type: Boolean,
      default: false,
    },
    reloadcard: {
      type: Boolean,
      default: false,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    showcalendar: {
      type: Boolean,
      default: false,
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    bulkOptions: {
      type: Array,
      default: () => [],
    },
    showMapPin: {
      type: Boolean,
      default: false,
    },
    buttontext: {
      type: String,
      default: "Submit",
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    showfilter: {
      type: Boolean,
      default: true,
    },
    showSortFilter: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      isSort: false,
      timeout: null,
      handleSearch: "",
      showOption: "Show All",
      options: ["Show All", "Active", "Deactivated"],
      sortOptions: [
        {
          name: "A to Z",
        },
        {
          name: "Z to A",
        },
        {
          name: "Newest First",
        },
      ],
    };
  },
  computed: {
    hasSearchSlot() {
      return !!this.$slots.search;
    },
    hasCalendarSlot() {
      return !!this.$slots.calendar;
    },
    hasSelectionSlot() {
      return !!this.$slots.selection;
    },
    hasButtonSlot() {
      return !!this.$slots.button;
    },
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  watch: {
    handleSearch(value) {
      clearTimeout(this.timeout);
      const self = this;
      this.timeout = setTimeout(() => {
        self.$emit("searchResult", value);
      }, 1000);
    },
  },
  methods: {
    handleClick() {
      if (this.$store.state.subscription) {
        const hasModule = this.$store.state.subscription.susbcribeModules.some(
          (item) => item.name === this.module
        );
        if (hasModule) {
          this.$emit("modalRequest");
        } else {
          this.$toasted.error(
            "Your current plan does not allow access to this app. Please contact your admin",
            { duration: 5000 }
          );
        }
      } else {
        this.$emit("modalRequest");
      }
    },
    handleSort(sortType) {
      switch (sortType) {
        case "A to Z":
          return this.$emit("sortType", "fname:asc");
        case "Z to A":
          return this.$emit("sortType", "fname:desc");
        case "Newest First":
          return this.$emit("sortType", "createdAt:desc");
        default:
          return null;
      }
    },
    handleSelect(option) {
      this.showOption = option;
      this.$emit("option", option);
    },
  },
};
</script>

<style scoped>
.selection {
  min-width: 116px;
  height: 40px;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  /* Whites/White */

  background: #ffffff;
  /* Blacks & Greys/Roman Silver */

  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 5px;
}
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
